import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { Logo } from './Logo';
import { FullScreenVideo } from './FullScreenVideo';
import chevronDown from '../assets/images/icons/rw-chevron-down.png';
import { Gallery } from './Gallery';
import { Video } from './EmbeddedVideo';

export const TopLevelPage = ({
  clients,
  galleryItems,
  title,
  vimeo_url,
  backgroundVideoUrl,
  fromPage,
}) => {
  const [isReelOpen, setIsReelOpen] = useState(false);

  const gallery = useRef(null);
  const scrollToGallery = () => {
    gallery.current.scrollIntoView({ behavior: 'smooth' });
  };

  const openReel = () => {
    setIsReelOpen(true);
  };

  const closeReel = () => {
    setIsReelOpen(false);
  };

  return (
    <>
      {isReelOpen && (
        <Video
          videoSrcURL={vimeo_url}
          videoTitle={`Move of - ${title}`}
          closeReel={closeReel}
        />
      )}

      <Logo color="white" logo={fromPage} />
      <FullScreenVideo
        videoUrl={backgroundVideoUrl}
        text={title}
        openReel={openReel}
      />
      <ClientsContainer>
        {clients.length > 0 && <ClientText> CLIENTS </ClientText>}
        <FlexContainer>
          {clients.map((client) => (
            <div key={client.Logo.asset.url}>
              <img src={client.Logo.asset.url} alt="customer-logo" />
            </div>
          ))}
        </FlexContainer>
      </ClientsContainer>
      <ChevronDown onClick={scrollToGallery}>
        <img src={chevronDown} alt="arrow-down" />
      </ChevronDown>
      <Gallery fromPage={fromPage} galleryRef={gallery} data={galleryItems} />
    </>
  );
};

const ChevronDown = styled.div`
  z-index: 9998;
  position: absolute;
  bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  @media only screen and (orientation: landscape) and (max-width: 700px) {
    bottom: 10px;
  }
`;

const ClientText = styled.p`
  font-size: 11px;
  text-align: center;
  color: var(--white);
`;

const FlexContainer = styled.div`
  display: flex;
`;

const ClientsContainer = styled.div`
  position: absolute;
  bottom: 80px;
  width: 100%;
  z-index: 1;
  justify-content: center;

  @media screen and (max-width: 600px) {
    bottom: 160px;
  }
  @media screen and (max-width: 321px) {
    bottom: 130px;
  }

  @media only screen and (orientation: landscape) and (max-width: 700px) {
    display: none;
  }

  div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      max-width: 140px;
      max-height: 90px;

      @media screen and (max-width: 600px) {
        max-width: 100px;
        max-height: 50px;
      }
    }
  }
`;

import { Link } from 'gatsby';
import React, { useState } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import chevronDown from '../assets/images/icons/chevron-down-black.png';

export const Gallery = ({ data, galleryRef, fromPage }) => {
  const { edges } = data;
  const firstItems = edges.slice(0, 12);
  const [posts, setPosts] = useState(firstItems);

  const showAll = () => {
    setPosts(edges);
  };

  return (
    <>
      <GalleryWrapper ref={galleryRef}>
        {posts.map((item) => (
          <GridStyles key={item.node.slug.current}>
            <Link to={`/${fromPage}/${item.node.slug.current}`}>
              <ImageContainer>
                <Img
                  fluid={item.node.gallery_image.asset.fluid}
                  alt={item.node.title}
                />
              </ImageContainer>
              <TextContainer>
                <h2> {item.node.title}</h2>
                {item.node._rawDescription ? (
                  <BlockContent blocks={item.node._rawDescription} />
                ) : (
                  <p> {item.node.description} </p>
                )}
                <span> Read more </span>
              </TextContainer>
            </Link>
          </GridStyles>
        ))}
      </GalleryWrapper>
      <div>
        {posts.length !== edges.length && (
          <ChevronDown onClick={showAll}>
            <img src={chevronDown} alt="Arrow down" />
          </ChevronDown>
        )}
      </div>
    </>
  );
};

const TextContainer = styled.div`
  padding: 20px 20px;
  margin-top: -20px;
  margin-right: 4%;
  margin-left: 4%;
  z-index: 3;
  background-color: var(--background);
  position: relative;
`;

const GalleryWrapper = styled.div`
  background-color: var(--background);
  padding: 4rem 8rem 0 8rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 0rem 4rem;
  /* grid-auto-rows: auto auto 300px; */

  @media screen and (max-width: 600px) {
    padding: 4rem 1rem 0 1rem;
  }
`;

const ImageContainer = styled.div`
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0 0 20px;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 20px;
`;

const GridStyles = styled.div`
  display: grid;
  /* Take your row sizing not from the pizzaStyles div, but from the  PizzaGridStyles grid */
  @supports not (grid-template-rows: subgrid) {
    --rows: auto auto 1fr;
  }
  grid-template-rows: var(--rows, subgrid);
  grid-row: span 1;
  grid-gap: 1rem;

  h2 {
    font-size: 15px;
    color: var(--black);
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 21px;
    color: #000;
    font-family: PlayfairDisplayBold;
  }
  p {
    color: #454545;
    min-height: 76px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 13px;
    line-height: 19px;
    font-weight: normal;
    letter-spacing: 0.2px;
    cursor: pointer;
    font-family: RalewayRegular;
  }

  span {
    letter-spacing: 0.2px;
    font-size: 11px;
    line-height: 40px;
    margin: 0 0 10px 0;
    color: #454545;
    font-family: RalewayBold;
  }
`;

const ChevronDown = styled.div`
  z-index: 9998;
  bottom: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
`;

import React from 'react';
import styled from 'styled-components';

export const Video = ({ videoSrcURL, videoTitle, closeReel, ...props }) => (
  <StyledVideo onClick={closeReel}>
    <iframe
      onClick={closeReel}
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </StyledVideo>
);

const StyledVideo = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    position: absolute;
    width: 60%;
    height: 80%;
    z-index: 9999;
    @media screen and (max-width: 800px) {
      width: 80%;
    }
  }
`;

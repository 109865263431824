import React from 'react';
import styled from 'styled-components';

export const FullScreenVideo = ({ active, videoUrl, text, openReel }) => (
  <FullWidthVideo active={active}>
    <FullWidthVideoBg>
      <video playsInline autoPlay muted loop key={videoUrl}>
        <source src={videoUrl} type="video/mp4;" />
      </video>
      {text && (
        <TextWrapper>
          <CenterText> {text} </CenterText>
          <div>
            <ViewReel onClick={openReel}> View Reel</ViewReel>
          </div>
        </TextWrapper>
      )}
    </FullWidthVideoBg>
  </FullWidthVideo>
);

const TextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 999;
  top: 30%;

  div {
    width: 100%;
    text-align: center;
    margin-top: 25px;
  }
`;

const CenterText = styled.h1`
  text-align: center;
  max-width: 40%;
  min-width: 600px;
  color: var(--white);
  line-height: 67px;
  margin-bottom: 0;
  color: var(--white);
  font-size: 50px;
  letter-spacing: 0.5px;

  @media screen and (max-width: 600px) {
    min-width: 80%;
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
`;

const ViewReel = styled.a`
  color: var(--white);
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  border-bottom: 1px solid var(--white);
  padding: 2px;
  font-size: 21px;
  font-family: RalewayRegular;
  font-style: normal;
  cursor: pointer;
  &:hover {
    transition: 200ms;
    transform: scale(1.1);
  }
  @media only screen and (orientation: landscape) {
    display: none;
  }
`;

const FullWidthVideo = styled.div`
  height: 100vh;
  min-height: 50vh;
  position: relative;
  overflow: hidden;

  @keyframes animatePosition {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.4;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  video {
    object-fit: cover;
    overflow: hidden;
    width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    animation: animatePosition 0.8s;
  }
`;

const FullWidthVideoBg = styled.div`
  overflow: hidden;
`;
